import { useAuth } from '@infinitusai/auth';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@infinitus/components/Button';
import { IconNames } from '@infinitus/components/Icon';
import VisualVariant from '@infinitus/types/visual-variant-types';
import useVoipClient from '@infinitus/voip/useVoipClient';
import { Page } from 'components/Page';
import { getSignOutPageUrl } from 'utils';

import RecordedGreetings from './RecordedGreetings';

function AccountPage() {
  const navigate = useNavigate();

  return (
    <Page
      breadcrumbs={[
        {
          title: 'Account',
          iconName: IconNames.ACCOUNT_CIRCLE,
        },
      ]}
      headerContent={
        <Stack direction="row" justifyContent="flex-end" spacing={2} width={1}>
          <DeleteSessionButton />

          <Button
            onClick={() => navigate(getSignOutPageUrl())}
            sx={{ marginLeft: 2, marginY: 'auto!important' }}
            text="Log out"
            variant={VisualVariant.FILLED}
          />
        </Stack>
      }
    >
      <RecordedGreetings />
    </Page>
  );
}

const DeleteSessionButton = () => {
  const voipClient = useVoipClient();
  const { isSuperAdmin, user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [deletingSession, setDeletingSession] = useState(false);
  const hasSuperAdminAccess = isSuperAdmin();

  // test user should be able to delete session
  if (!hasSuperAdminAccess && (user?.email || '').split('@')[1] !== 'team221824.testinator.com') {
    return null;
  }

  const deleteSession = async () => {
    setDeletingSession(true);
    await voipClient.deleteSession();
    setDeletingSession(false);
    enqueueSnackbar('Voip session deleted', { variant: 'success' });
  };

  return (
    <Button
      disabled={deletingSession}
      onClick={deleteSession}
      sx={{ marginLeft: 2, marginY: 'auto!important' }}
      text="Delete Voip Session (For Testing)"
      variant={VisualVariant.FILLED}
    />
  );
};

export default AccountPage;
