import { memoize } from 'lodash';

import { CohortInput, ExperimentInput } from '@infinitus/generated/frontend-common';

export const DISABLE_FT_JOIN_CALL_UX_EXPERIMENT = 'control';
export const ENABLE_FT_JOIN_CALL_UX_EXPERIMENT = 'treatment';

// All participants will be in the control cohort unless explicitly assigned to
// the treatment cohort by an engineer in the db
const cohortDistribution: CohortInput[] = [
  { cohortName: DISABLE_FT_JOIN_CALL_UX_EXPERIMENT, weight: 1 },
  { cohortName: ENABLE_FT_JOIN_CALL_UX_EXPERIMENT, weight: 0 },
];

function fasttrackJoinCallUxExperimentParticipant(userEmail: string): ExperimentInput {
  const experimentName = 'fasttrack_join_call_ux_experiment';
  // participantID should be unique per experimental subject - i.e. if we want to apply treatments to calls;
  // each call should have a distinct participantID. The sample below will do the trick but is a bit overkill.
  const participantID = userEmail;

  return {
    participantID,
    experimentName,
    cohortDistribution,
  };
}

export const buildFasttrackJoinCallUxExperimentParticipant = memoize(
  fasttrackJoinCallUxExperimentParticipant,
  (userEmail) => {
    return userEmail;
  }
);
