import { AppName } from '@infinitusai/api';
import { ReactNode } from 'react';

import { NexmoClientProvider } from '@infinitus/nexmo';
import { NexmoClientProviderProps } from '@infinitus/nexmo/types';

import VoipProvider from './VoipProvider';
import LiveKitClientWrapperProvider from './livekit/LiveKitClientWrapperProvider';

interface Props {
  appName: AppName;
  children: ReactNode;
  logEvent: NonNullable<NexmoClientProviderProps['logEvent']>;
}

export function AllVoipProviders({ appName, children, logEvent }: Props) {
  return (
    <NexmoClientProvider appName={appName} logEvent={logEvent}>
      <LiveKitClientWrapperProvider>
        <VoipProvider>{children}</VoipProvider>
      </LiveKitClientWrapperProvider>
    </NexmoClientProvider>
  );
}
