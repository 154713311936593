import { useContext } from 'react';

import { LiveKitClientProvider } from './LiveKitClientWrapperContext';
import { LiveKitClient } from './LiveKitClientWrapperContext';

function useLiveKitClient(): LiveKitClient {
  const liveKitClient = useContext(LiveKitClientProvider);

  return liveKitClient;
}

export default useLiveKitClient;
