import { LiveKitRoom, RoomAudioRenderer } from '@livekit/components-react';
import { AxiosError } from 'axios';
import { AudioCaptureOptions, Room } from 'livekit-client';
import { PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';

import useApi from '@infinitus/hooks/useApi';
import useSnackbar from '@infinitus/hooks/useCustomSnackbar';
import { infinitusai } from '@infinitus/proto/pbjs';

import {
  LIVEKIT_SERVER,
  LiveKitClient,
  LiveKitClientProvider,
} from './LiveKitClientWrapperContext';
import { StartAudioModal } from './StartAudioModal';

interface RoomAccess {
  audio: boolean | AudioCaptureOptions;
  authToken: string;
  room: Room;
}

export default function LiveKitClientWrapperProvider({ children }: PropsWithChildren<{}>) {
  const { api } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [roomAccess, setRoomAccess] = useState<RoomAccess | undefined>(undefined);
  const roomAccessRef = useRef<RoomAccess | undefined>();
  roomAccessRef.current = roomAccess;

  const join: LiveKitClient['join'] = useCallback(
    async ({
      orgUuid,
      taskUuid,
      callUuid,
      muteMicByDefault,
      autoGainControl,
      echoCancellation,
      noiseSuppression,
      microphoneDeviceId,
      speakerDeviceId,
    }) => {
      // TODO: protect against joining an already joined call
      console.info('[LiveKit] call join room');
      try {
        const body = new infinitusai.be.GetLiveKitJwtRequest({ taskUuid, callUuid });
        const resp = await api.getLiveKitJwt(null, orgUuid, body);

        const roomAccessInfo: RoomAccess = {
          authToken: resp.data.jwt,
          room: new Room(),
          audio: muteMicByDefault
            ? false
            : {
                autoGainControl,
                echoCancellation,
                noiseSuppression,
                deviceId: microphoneDeviceId ?? '',
              },
        };
        // TODO: remove listeners
        roomAccessInfo.room.on('disconnected', (r) => console.info('disconnected because', r));

        setRoomAccess(roomAccessInfo);
        await roomAccessInfo.room.switchActiveDevice('audiooutput', speakerDeviceId ?? '');
      } catch (e: any) {
        console.error('Failed to join call', e);
        let errorDisplay = 'Failed to join call';
        if (e instanceof AxiosError && e.status === 400) {
          errorDisplay = `Failed to join call: ${e.message}`;
        }
        enqueueSnackbar(errorDisplay, { variant: 'error' });
      }
    },
    [api, enqueueSnackbar]
  );

  const leave: LiveKitClient['leave'] = useCallback(async () => {
    console.info('[LiveKit] call leave room');
    try {
      await roomAccessRef.current?.room.disconnect();
    } catch (e: any) {
      console.error(e);
    }
    const roomAccessInfo: RoomAccess = {
      authToken: '',
      room: new Room(),
      audio: false,
    };
    setRoomAccess(roomAccessInfo);
  }, []);

  const value: LiveKitClient = useMemo(
    () => ({
      join,
      leave,
    }),
    [join, leave]
  );

  return (
    <LiveKitClientProvider.Provider value={value}>
      <LiveKitRoom
        audio={roomAccess?.audio}
        connect={!roomAccess?.room.name}
        onConnected={() => console.log('[LiveKit] onConnected')}
        onDisconnected={() => console.log('[LiveKit] onDisconnected')}
        onError={(e) => console.error('[LiveKit] onError', e)}
        room={roomAccess?.room}
        serverUrl={LIVEKIT_SERVER}
        token={roomAccess?.authToken}
      >
        <RoomAudioRenderer />
        <StartAudioModal />
        {children}
      </LiveKitRoom>
    </LiveKitClientProvider.Provider>
  );
}
