import { AppName } from '@infinitusai/api';
import { ReactNode } from 'react';

import { LogEventFunction } from './NexmoClientService';

export enum ConnectionState {
  UNKNOWN = 'Unknown',
  DISCONNECTED = 'Disconnected',
  CONNECTING = 'Audio connecting...',
  CONNECTED = 'Audio connected',
}

export interface NexmoClientProviderProps {
  appName: AppName;
  children?: ReactNode;
  logEvent?: LogEventFunction;
}
