import { createContext } from 'react';

type JoinAudioSettings = {
  autoGainControl: boolean;
  echoCancellation: boolean;
  microphoneDeviceId?: string;
  muteMicByDefault: boolean;
  noiseSuppression: boolean;
  speakerDeviceId?: string;
};

type JoinArgs = JoinAudioSettings & {
  callUuid: string;
  orgUuid: string;
  taskUuid: string;
};

export interface LiveKitClient {
  join: (args: JoinArgs) => void;
  leave: () => void;
}

const stub = () => {
  throw new Error('not implemented');
};

const DEFAULT_LIVEKIT_CLIENT: LiveKitClient = {
  join: stub,
  leave: stub,
};

export const LIVEKIT_SERVER = 'wss://outbound-9zve9lcc.livekit.cloud';

export const LiveKitClientProvider = createContext<LiveKitClient>(DEFAULT_LIVEKIT_CLIENT);
