import { useAudioPlayback } from '@livekit/components-react';

import { Modal } from '@infinitus/components/Modal';

/**
 * Many web browsers block audio autoplay until there is user interaction with
 * the page. This modal prompts the user to explicitly start audio playback,
 * satisfying browser autoplay policies.
 *
 * See MDN docs on autoplay policy:
 * - https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Best_practices#autoplay_policy
 * - https://developer.mozilla.org/en-US/docs/Web/Media/Autoplay_guide#autoplay_availability
 */
export function StartAudioModal() {
  const { canPlayAudio, startAudio } = useAudioPlayback();
  return (
    <Modal
      hideCancelButton
      isOpen={!canPlayAudio}
      onCancel={() => startAudio()}
      onConfirm={() => startAudio()}
      title="Resume audio playback"
    />
  );
}
