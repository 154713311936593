import { CohortInput, ExperimentInput } from '@infinitus/generated/frontend-common';

export const DEFAULT_NEW_VONAGE_SDK_COHORT_KEY = 'control';
export const FALLBACK_OLD_VONAGE_SDK_COHORT_KEY = 'treatment';

// All participants will be in the control cohort unless explicitly assigned to
// the treatment cohort by an engineer in the db
const cohortDistribution: CohortInput[] = [
  { cohortName: DEFAULT_NEW_VONAGE_SDK_COHORT_KEY, weight: 1 },
  { cohortName: FALLBACK_OLD_VONAGE_SDK_COHORT_KEY, weight: 0 },
];

export function nexmoSdkFallbackParticipant(userEmail: string): ExperimentInput {
  const experimentName = 'nexmo_sdk_fallback';
  // participantID should be unique per experimental subject - i.e. if we want to apply treatments to calls;
  // each call should have a distinct participantID. The sample below will do the trick but is a bit overkill.
  const participantID = userEmail;
  return {
    participantID,
    experimentName,
    cohortDistribution,
  };
}
