import { WebSocketListeners } from './links';

const DEBUG_MODE_KEY = 'infWsDebugMode';

function isWsInDebugMode() {
  const isDebugMode = localStorage.getItem(DEBUG_MODE_KEY) === 'true';
  return isDebugMode;
}

function toggleDebugMode() {
  const isDebugMode = isWsInDebugMode();
  localStorage.setItem(DEBUG_MODE_KEY, String(!isDebugMode));
  (window as any).INF_WS_DEBUG_MODE = isWsInDebugMode();
}

(window as any).INF_WS_DEBUG_MODE = isWsInDebugMode();
(window as any).toggleDebugMode = toggleDebugMode;

export function isWSDebugEnabled() {
  return (window as any).INF_WS_DEBUG_MODE;
}

export const WEBSOCKET_DEBUG_LISTENERS: WebSocketListeners = {
  connecting: (retry) => {
    if (isWSDebugEnabled()) {
      console.info('[GraphQL WebSocket] connecting');
    }
  },
  opened: (socket) => {
    if (isWSDebugEnabled()) {
      console.info('[GraphQL WebSocket] opened', { socket });
    }
  },
  connected: (socket, payload) => {
    if (isWSDebugEnabled()) {
      console.info('[GraphQL WebSocket] connected', { socket, payload });

      // Replace socket.send with a wrapper that logs the outgoing message
      if (socket instanceof WebSocket) {
        const originalSend = socket.send.bind(socket);
        socket.send = (data: string) => {
          try {
            const parsedData = JSON.parse(data);
            console.info('[GraphQL WebSocket] send', parsedData);
          } catch (e) {
            console.error('[GraphQL WebSocket] error parsing send data. Raw string data: ', data);
          } finally {
            return originalSend(data);
          }
        };
      }
    }
  },
  ping: (received, payload) => {
    if (isWSDebugEnabled()) {
      console.info('[GraphQL WebSocket] ping', { received, payload });
    }
  },
  pong: (received, payload) => {
    if (isWSDebugEnabled()) {
      console.info('[GraphQL WebSocket] pong', { received, payload });
    }
  },
  message: (message) => {
    if (isWSDebugEnabled()) {
      console.info('[GraphQL WebSocket] message', { message });
    }
  },
  closed: (event) => {
    if (isWSDebugEnabled()) {
      console.info('[GraphQL WebSocket] closed', { event });
    }
  },
  error: (error) => {
    if (isWSDebugEnabled()) {
      console.info('[GraphQL WebSocket] error', { error });
    }
  },
};
