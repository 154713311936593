import { useAuth } from '@infinitusai/auth';
import Tracker from '@openreplay/tracker';
import { Message } from 'graphql-ws';
import { useEffect } from 'react';

import { WebSocketListeners } from '@infinitus/apollo/links';
import {
  OPEN_REPLAY_ENABLED_COHORT_KEY,
  openReplayParticipant,
  useExperiment,
} from '@infinitus/hooks/useExperiment';
import { UUID } from '@infinitus/types';
import { excludeProp } from '@infinitus/utils/excludeProp';
import { isLocalhost } from '@infinitus/utils/isLocalhost';

const createTracker = () => {
  const ingestPoint = process.env.REACT_APP_OPENREPLAY_INGEST_POINT;
  const projectKey = process.env.REACT_APP_OPENREPLAY_PROJECT_KEY;
  // Set __DISABLE_SECURE_MODE to allow OpenReplay to work from localhost https://docs.openreplay.com/en/v1.7.0/sdk/constructor/#security
  const disableSecureMode = isLocalhost;
  if (!projectKey) {
    console.info(
      'REACT_APP_OPENREPLAY_PROJECT_KEY is not set. OpenReplay will not track this session.'
    );
    return undefined;
  }
  if (!ingestPoint) {
    console.info(
      'REACT_APP_OPENREPLAY_INGEST_POINT is not set. OpenReplay will not track this session.'
    );
    return undefined;
  }
  return new Tracker({
    projectKey,
    ingestPoint,
    __DISABLE_SECURE_MODE: disableSecureMode,
  });
};

// Global instances since there only needs to be one
const tracker = createTracker();
const gqlWsHook = tracker?.trackWs('graphql-ws');

const useOpenReplayTracker = () => {
  const { user } = useAuth();
  const { isInCohort } = useExperiment({
    experiment: openReplayParticipant(user?.email || ''),
  });

  useEffect(() => {
    if (tracker && user?.email && isInCohort(OPEN_REPLAY_ENABLED_COHORT_KEY)) {
      void tracker.start({
        userID: user.email,
      });
      return () => {
        tracker.stop();
      };
    }
  }, [isInCohort, user?.email]);
};

export const OpenReplayTracker = () => {
  useOpenReplayTracker();
  return null;
};

// Add additional events to this union
export type OpenReplayCustomEvent =
  | {
      callUuid: UUID;
      eventName: 'CallPageImpression';
      orgUuid: UUID;
      taskUuid: UUID;
    }
  | {
      eventName: 'TaskPageImpression';
      orgUuid: UUID;
      taskUuid: UUID;
    };

export const trackOpenReplayEvent = (event: OpenReplayCustomEvent) => {
  const eventWithoutEventName = excludeProp(event, 'eventName');
  if (tracker) {
    tracker.event(event.eventName, eventWithoutEventName);
  }
};

export const OPEN_REPLAY_WS_LISTENERS: WebSocketListeners = {
  message: (message: Message) => {
    // Data from subscriptions come through as 'next' messages
    if (!gqlWsHook || message.type !== 'next') {
      return;
    }
    gqlWsHook(message.type, JSON.stringify(message), 'down');
  },
  connected: (socket) => {
    if (!gqlWsHook || !(socket instanceof WebSocket)) {
      return;
    }
    // Replace socket.send with a wrapper that logs the outgoing message
    const originalSend = socket.send.bind(socket);
    socket.send = (data: string) => {
      try {
        const parsedData = JSON.parse(data);
        const message = parsedData as Message;
        // We only want to track the payload of subscribe messages
        // theres lots of ping/pong messages that we don't want to track
        if (message.type === 'subscribe') {
          gqlWsHook(message.type, data, 'up');
        }
      } catch (e) {
        console.error('[OpenReplayTracker] error parsing data. Raw string data: ', data);
      } finally {
        return originalSend(data);
      }
    };
  },
};
