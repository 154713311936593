import { createContext } from 'react';

import { NexmoAttachRequestSource } from '@infinitus/nexmo/NexmoContext';

export enum VoipClient {
  VONAGE = 'VOANGE',
  LIVEKIT = 'LIVEKIT',
}

interface JoinOptions {
  autoGainControl?: boolean;
  callUuid: string;
  deviceId?: string;
  echoCancellation?: boolean;
  force?: boolean;
  muteMicByDefault: boolean;
  nexmoConversationId?: string;
  noiseSuppression?: boolean;
  orgUuid: string;
  source: NexmoAttachRequestSource;
  speakerDeviceId?: string;
  taskUuid: string;
  voipClient: VoipClient;
}

export interface IVoipContext {
  deleteSession: () => Promise<void>;
  join: (options: JoinOptions) => Promise<void>;
  leave: () => Promise<void>;
  selectedVoipClient?: VoipClient;
  startNewSession: () => Promise<void>;
}

const stub = () => {
  throw new Error('not implemented');
};

const defaultContext: IVoipContext = {
  join: stub,
  leave: stub,
  startNewSession: stub,
  deleteSession: stub,
};

export const VoipContext = createContext<IVoipContext>(defaultContext);
